import React from "react";
import {graphql} from "gatsby";

const Dashboard = ({}: any) => {
    return <>
        <h1>Dashword</h1>
    </>
}
export default Dashboard;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
